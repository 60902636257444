/* Loader.css */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 7px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite, changeColor 3s linear infinite;
  border-top-color: red; /* Initial color */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Keyframe for changing colors */
@keyframes changeColor {
  0% {
    border-top-color: red;
  }
  25% {
    border-top-color: blue;
  }
  50% {
    border-top-color: green;
  }
  75% {
    border-top-color: yellow;
  }
  100% {
    border-top-color: purple;
  }
}
