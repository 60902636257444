.container {
  width: 80%;
  margin: 50px auto;
  font-family: 'Arial, sans-serif';
  
}

.stepIndicator {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 40px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  flex: 1;
  transition: color 0.3s ease;
}

.step:not(:last-child) {
  padding-right: 20px;
}

.icon {
  font-size: 24px;
  margin-bottom: 8px;
  color: lightgray;
  transition: color 0.3s ease;
}

.label {
  font-size: 14px;
  text-align: center;
  color: lightgray;.container {
    width: 80%;
    margin: 50px auto;
    font-family: 'Arial, sans-serif';
  }
  
  
  .connector {
    position: absolute;
    top: 12px;
    right: -50%;
    width: 100%;
    height: 2px;
    background-color: lightgray;
    z-index: -1;
  }
  
  .current .icon {
    color: #3498db;
  }
  
  .current .label {
    color: #3498db;
  }
  
  .completed .icon {
    color: #2ecc71;
  }
  
  .completed .label {
    color: #2ecc71;
  }
  
  .upcoming .icon {
    color: lightgray;
  }
  
  .upcoming .label {
    color: lightgray;
  }
  
  .content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-height: 200px;
    background-color: #bfe5f3;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
    position: relative;
  }
  
  .content-enter {
    opacity: 0;
  }
  
  .content-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  
  .content-exit {
    opacity: 1;
  }
  
  .content-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  .completeButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .completeButton:hover {
    background-color: #2980b9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .stepIndicator {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .step {
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
    }
  
    .connector {
      display: none;
    }
  
    .label {
      margin-left: 10px;
    }
  }
  
  transition: color 0.3s ease;
}

.connector {
  position: absolute;
  top: 12px;
  right: -50%;
  width: 100%;
  height: 2px;
  background-color: lightgray;
  z-index: -1;
}

.current .icon {
  color: #3498db;
}

.current .label {
  color: #3498db;
}

.completed .icon {
  color: #2ecc71;
}

.completed .label {
  color: #2ecc71;
}

.upcoming .icon {
  color: lightgray;
}

.upcoming .label {
  color: lightgray;
}

.content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  min-height: 200px;
  background-color: #cff4fc;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  position: relative;
}

.content-enter {
  opacity: 0;
}

.content-enter-active {
  opacity: 1;
}

.content-exit {
  opacity: 1;
}

.content-exit-active {
  opacity: 0;
}

.completeButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.completeButton:hover {
  background-color: #2980b9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .stepIndicator {
    flex-direction: column;
    align-items: flex-start;
  }

  .step {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .connector {
    display: none;
  }

  .label {
    margin-left: 10px;
  }
}
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.previousButton,
.nextButton {
  padding: 10px 20px;
  background-color: #c90057;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.previousButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.previousButton:hover:not(:disabled),
.nextButton:hover {
  background-color: #7f083c;
}
.content-enter {
  opacity: 0;
}

.content-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.content-exit {
  opacity: 1;
}

.content-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
