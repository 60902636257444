


.tblFirstTdSecond
{
    /* margin-top: -70px; */

height: 400px;
overflow: auto;
    
}
.small30 {
    font-size: 10pt;
    font-weight: 400;
  }
  .otp-container {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust space between input boxes */
}

.otp-input {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.otp-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.is-invalid .otp-input {
  border-color: #dc3545;
  box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
  
}

.invalid-feedback {
  display: block;
  color: #dc3545;
  margin-top: 5px;
}
