
.footerColor {
  /* position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  text-align: center; */
  background-color: #c90057;
  /* color: white; */
}

.rounded-social-buttons .social-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    /* width: 3.125rem;
    height: 3.125rem; */
    border: 0.125rem solid transparent;
    padding-right:30px;
    text-decoration: none;
    text-align: center;
    color: #fefefe;
    font-size: 1rem;
    font-weight: bold;
    
    border-radius: 1.6875rem;
    transition: all 0.5s ease;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    float: right;
  }
