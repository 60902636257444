.fullscreen-modal {
  min-width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  overflow: hidden;
  outline: 0;
  position: fixed;
}

.fullscreen-modal .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
}

.fullscreen-modal .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.fullscreen-modal .modal-body {
  overflow-y: auto;
}
.image-container {
  position: relative;
  
}

.passport-photo {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

