.slideshow-container {
  width: 70%;
  position: relative;
  margin: auto;
  height:350px;
}
.imageStyle{
width: 100%;
height: 100%;
}
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  border:none;
  background-color:transparent
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev:hover, .next:hover {
background-color: rgba(0,0,0,0.8);
}

.displayNone{
  display:none;
}
.displayBlock {
  display: block;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 13px;
  width: 100%;
  text-align: center;
  float: left;
}

/* Fading animation */
.fade {
  width: 100%;
  height: 100%;
  animation-name: fade;
  animation-duration: 1.5s;
}
.imgHome
{
 width:40%;
 height:30%;
 padding:20px
}
.imgHomeRight
{
 width:100%;
 height:40%;
}
.imgHomeMain
{
display: flex;
}
.homeTextGreen
{
margin-top: 20px;
padding: 20px;
border-top:10px solid rgb(4, 65, 4);
background-color: rgb(225, 253, 198);
border-radius: 10px;
border-bottom:3px solid rgb(4, 65, 4);

}
.homeTextOrange
{
  margin-top: 20px;
  padding: 20px;
border-top:10px solid rgb(155, 102, 3);
background-color: rgb(253, 227, 178);
border-radius: 10px;
border-bottom:3px solid rgb(155, 102, 3);
}
.homeTextBlue
{
  margin-top: 20px;
  padding: 20px;
border-top:10px solid rgb(1, 90, 112);
background-color: rgb(189, 234, 245);
border-radius: 10px;
border-bottom:3px solid rgb(1, 90, 112);
}
.textGreensub
{
background:rgb(4, 65, 4);
color:white;
padding: 10px;
}
.textOrangesub
{
background:rgb(155, 102, 3);
color:white;
padding: 10px;
}
.textBluesub
{
background:rgb(1, 90, 112);
color:white;
padding: 10px;
}
/* .imgHome:hover {
  -ms-transform: scale(1.5);
-webkit-transform: scale(1.5); 
transform: scale(1.5); 
} */
.gridTest {
  display: flex;
  width:100%;
  grid-template-columns:1fr 300px;
  gap: 15px;
}
@keyframes fade {
from {opacity: .4} 
to {opacity: 1}
}
@media screen and (max-width: 900px) {
  .imgHome
  {
     width:90%;
     height:30%;
     padding:20px
  }
  .imgHomeMain
{
display: grid;
}
}

@media screen and (max-width: 700px) {
 
  .imgHome
  {
     width:90%;
     height:30%;
     padding:20px
  }
  .imgHomeMain
{
display: grid;
}
}
@media only screen and (min-width: 768px) {
  .imgHome
  {
     width:100%;
     height:30%;
     padding:20px
  }
}
@media only screen and (min-width: 1024px) {
  .imgHome
  {
     width:100%;
     height:30%;
     padding:20px
  }
}