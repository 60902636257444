.chat-column {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;
    background-color: #f1f1f1;
    border-left: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
    z-index: 10;
  }
  .participant-name {
    position: absolute;
    color: white;
    font-size: 16px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    bottom: 10px;
  }
  
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
  }
  .conference-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
  }
  
  .join-room {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .conference-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    flex: 1;
  }
  
  .video-wrapper {
    position: relative;
    border: 2px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  video {
    width: 100%;
    height: 100%;
  }
  
  .controls {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: #f1f1f1;
  }
  
  .controls button {
    padding: 10px;
    font-size: 18px;
  }
  
  .chat-column {
    position: absolute;
    right: 10px;
    top: 50px;
    width: 300px;
    height: calc(100% - 60px);
    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .chat-message {
    margin-bottom: 10px;
  }
  
  .own-message {
    text-align: right;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
  }
  
  .chat-input button {
    padding: 10px 15px;
  }
  .chat-column {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #f1f1f1;
    border-left: 1px solid #ccc;
    overflow-y: auto;
    display: none;
  }
  
  .chat-messages {
    padding: 10px;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 10px;
  }
  
  .chat-message.self {
    background-color: #d1e7dd;
    text-align: right;
  }
  
  .chat-message.other {
    background-color: #f8d7da;
    text-align: left;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .chat-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  .chat-input button {
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  