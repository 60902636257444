.page {
  width: 200px;
  height: 300px;
  perspective: 1000px;
  cursor: pointer;
}

.page-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.page-front,
.page-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.page-front {
  background-color: #eaeaea;
  z-index: 2;
}

.page-back {
  background-color: #ccc;
  transform: rotateY(180deg);
}

.page.flip .page-inner {
  transform: rotateY(180deg);
}
