/* datepicker-custom.css */

/* Example to change the border color */
/* .react-datepicker {
    background-color: #7f083c !important;
  }
   */
  /* Example to change the day picker's border color */
  /* .react-datepicker__day {
    color: white;
  } */
  
  /* Example to change the text color of the calendar */
  .react-datepicker__current-month,
  .react-datepicker__day-name
  {
    color: white;
  }
 
  .react-datepicker__header {
    background-color: #c90057 !important; /* Light red background */
    color: white;
    
  }
  .react-datepicker
  {
    border-left: 2px solid #c90057 !important;
    border-right: 2px solid #c90057 !important;
    border-bottom: 5px solid #c90057 !important;
  }
  .customDatePickerWidth, 
  .customDatePickerWidth > div.react-datepicker-wrapper, 
  .customDatePickerWidth > div > div.react-datepicker__input-container
  .customDatePickerWidth > div > div.react-datepicker__input-container input {
     width: 100%;
     height: 100%;
  }
  .react-datepicker__input-container  {
      width: inherit;
      height: inherit;
    }
    .react-datepicker__input-container input {
      width: inherit;
      height: 100%;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
    .signboxx {
      /* box-shadow: 1px 3px 7px -2px rgba(0, 0, 0, 0.68); */
      max-width: 100%;
      height: 200px;
      border: 1px solid black;
      
      border-radius: 5px;
      width: 300px;
    }
    .sig-canvas {
      border: 1px solid #d1d3e2; /* Ensure there is a visible border */
      border-radius: 5px;
  }
  .curSig {
    cursor: url(./../assets/images/Patent-Pen-icon.png) 0 30, progress;
  }
  .shine-button {
    position: relative;
    overflow: hidden;
    background: linear-gradient(to right, #007bff, #0056b3); /* Button background */
    color: #fff;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
}

.shine-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    width: 4px;
    height: 100%;
    background: white;
    transform: skewX(-25deg);
    filter: blur(2px);
    animation: white-shine 1s infinite ease-in-out; /* Continuous animation */
    z-index: 1;
}

@keyframes white-shine {
    0% {
        left: -75%;
    }
   
    100% {
        left: 150%;
    }
}
