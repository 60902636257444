ul, li {
  list-style: none outside none;
  
}
/* basic menu styles */
.nav-menu {
  display: block;
  background: #7f083c;
  
  /* width:951px;
  margin: 50px auto 150px; */
}
.nav-menu > li {
  display: inline;
  float:left; 
  padding-left: 2px;    
  border-right:1px solid #7f083c;
  position: relative;
  
}
.nav-menu > li:last-child {
  border-right: none;
  
}
.nav-menu li a {
  color: #fff;
  display: block;
  text-decoration: none;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  /* font-smoothing: antialiased;  */
 text-transform: capitalize;
  overflow: visible;
 
  font-size: 16px;
  /* font-weight: bold; */
  padding: 15px 30px 15px 31px;     
}

/* animation domination */
.three-d {
  /* -webkit-perspective: 200px;
  -moz-perspective: 200px;
  -ms-perspective: 200px;
  -o-perspective: 200px;
  perspective: 200px; */
  -webkit-transition: all .07s linear;
  -moz-transition: all .07s linear;
  -ms-transition: all .07s linear;
  -o-transition: all .07s linear;
  transition: all .07s linear;
  position: relative;
  
}

.three-d:not(.active):hover {
  cursor: pointer;
  
}

.three-d:not(.active):hover .three-d-box, 
.three-d:not(.active):focus .three-d-box {
  -wekbit-transform: translateZ(-25px) rotateX(90deg);
  -moz-transform: translateZ(-25px) rotateX(90deg);
  -o-transform: translateZ(-25px) rotateX(90deg);
  -ms-transform: translateZ(-25px) rotateX(90deg);
  transform: translateZ(-25px) rotateX(90deg);
}

.three-d-box {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  -webkit-transform: translatez(-25px);
  -moz-transform: translatez(-25px);
  -ms-transform: translatez(-25px);
  -o-transform: translatez(-25px);
  transform: translatez(-25px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.front {
  -webkit-transform: rotatex(0deg) translatez(25px);
  -moz-transform: rotatex(0deg) translatez(25px);
  -ms-transform: rotatex(0deg) translatez(25px);
  -o-transform: rotatex(0deg) translatez(25px);
  transform: rotatex(0deg) translatez(25px);
}

.back {
  -webkit-transform: rotatex(-90deg) translatez(25px);
  -moz-transform: rotatex(-90deg) translatez(25px);
  -ms-transform: rotatex(-90deg) translatez(25px);
  -o-transform: rotatex(-90deg) translatez(25px);
  transform: rotatex(-90deg) translatez(25px);
  color: #FFE7C4;
}

.front, .back {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #c90057;
  padding: 15px 30px 15px 31px; 
  color: white;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -ms-pointer-events: none;
  -o-pointer-events: none;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.nav-menu li a:hover .front,
.nav-menu li a:hover .back {
  border-radius: 15px;
  background-color: #7f083c;
  -webkit-background-size: 5px 5px;
  background-size: 5px 5px;
  background-position: 0 0, 30px 30px;
  background-image: -webit-linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c), linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c);
  background-image: -moz-linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c), linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c);
  background-image: -ms-linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c), linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c);
  background-image: -o-linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c), linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c);
  background-image: linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c), linear-gradient(45deg, #7f083c 25%, transparent 25%, transparent 75%, #7f083c 75%, #7f083c);
}
.nav-menu ul {
  
  position: absolute;
  left: -40px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  width: 200px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform-origin: 0px 0px;
  -moz-transform-origin: 0px 0px;
  -ms-transform-origin: 0px 0px;
  -o-transform-origin: 0px 0px;
  transform-origin: 0px 0px;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  -ms-transform: rotateX(-90deg);
  -o-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.nav-menu > li:hover ul {
  
  display: block;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.nav-menu li ul .active .front,
.nav-menu li ul .active .back
{
  border-radius: 15px;
  background-color: #c90057;
  -webkit-background-size: 5px 5px;
  background-size: 5px 5px;
  background-position: 0 0, 30px 30px;
  
}
.custom-table {
  border-bottom: 4px solid #7f083c;
  border-left: 1px solid #7f083c;
  border-right: 1px solid #7f083c;
  border-radius: 5px;
}

.table thead th {
  background-color: #7f083c;
  color: white;
  border: 1px solid #7f083c;
}

.even-row {
  background-color: #7f083c !important;
  color: white;
}

.odd-row {
  background-color: #7f083c !important;
}
.pagination .page-link {
color: #7f083c;
background-color: white;
}

.pagination .page-link:hover {
background-color: #ffffff;
border-color: #7f083c;
color: #7f083c;
}

.pagination .page-item.active .page-link {
background-color: #7f083c;
border-color: #7f083c;
}
.custom-search {
border-color: 1px solid #7f083c;

}
.table-striped tbody tr:nth-of-type(odd) {
background-color: red;
}

.table-striped tbody tr:nth-of-type(even) {
background-color: green;
}
.no-print {

}
/* .gridGet
{
width:100px !important
} */
@media print {
.no-print {
  display: none !important;
}

}

/* .custom-pagination .pagination {
  width: 100px !important;
  margin: 0 auto;
} */