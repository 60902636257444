.animated-word {
  font-size: 25px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(
    90deg,
    #ff0080,
    #ff8c00,
    #40e0d0,
    #ff0080
  );
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

/* Shining Animation */
@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
