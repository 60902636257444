.live-chat-button {
  bottom: 30px; /* Adjust based on your layout */
  right: 20px;  /* Adjust based on your layout */
  padding: 15px 25px; /* Adjust padding for a larger button */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 50px; /* Rounded corners */
  font-size: 18px; /* Font size */
  font-weight: bold; /* Bold font weight */
  cursor: pointer; /* Cursor changes to pointer on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Soft shadow effect */
  transition: background-color 0.3s ease; /* Smooth transitions */
  outline: none; /* Remove outline */
  
  text-align: center;
}


/* Hover effect */
.live-chat-button:hover {
  transform: translateY(-3px); /* Slightly lift the button */
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.4); /* More pronounced shadow on hover */
}

/* Blinking Animation */
@keyframes blink {
  0% {
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.5), 0 0 2px rgba(255, 255, 255, 0.5), 0 0 2px rgb(4, 139, 15);
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 2px rgba(255, 255, 255, 1), 0 0 2px rgba(255, 255, 255, 1), 0 0 2px rgb(0, 255, 72);
    opacity: 0.5; /* Reduced opacity to create blinking effect */
  }
  100% {
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.5), 0 0 2px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 123, 255, 1);
    opacity: 1;
  }
}

/* Blinking effect */
.blink {
  animation: blink 1.5s infinite; /* Adjust the duration as needed */
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.button-container {
  position: fixed;
  bottom: 50px; /* Adjust as needed */
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}