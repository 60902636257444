.logoText
{
    font-family:"Roboto" ;
    font-size: 20pt;
    font-weight: bold;
    color: white;
    padding-right: 5px;
    /* padding-left: 5px; */
}
.garmanFont
{
    font-family:"Garamond" ;
}
.garmanFontReg
{
    font-family:"Garamond" ;
    font-weight: bold;
    font-size: 14pt;

}
.garmanFontRed
{
    font-family:"Garamond" ;
    font-size: 16pt;
    font-weight: bold;
    color: red;
    /* padding-left: 5px; */
}
.garmanFontGreen
{
    font-family:"Garamond" ;
    font-size: 16pt;
    font-weight: bold;
    color: green;
    /* padding-left: 5px; */
}
.mobNavBarMain
{
    display: inline-block;
}
@media screen and (max-width: 900px) {
    .imgHome
    {
       width:90%;
       height:30%;
       padding:20px
    }
    .logoText
{
    font-family:"Roboto" ;
    font-size: 12pt;
    font-weight: bold;
}
.mobNavBarMain
{
    display: none;
}
  }

  @media screen and (max-width: 700px) {
   
    .imgHome
    {
       width:90%;
       height:30%;
       padding:20px
    }
    .logoText
{
    font-family:"Roboto" ;
    font-size: 12pt;
    font-weight: bold;
}
.mobNavBarMain
{
    display: none;
}
  }
  .nav-link{
    font-family: 'Roboto', sans-serif;
    /* background:#c90057 !important; */
  }
 .navbar-toggler {
    border:2px solid #fff !important;
   
   color: #fff;
}
.navbar {
    text-align: center;
}

