
  .hover-icons {
    display: none;
  }
  
  .containerIcon:hover .table-container {
    display: none;
   
  }
  
  .containerIcon:hover .hover-icons {
    display: inline; 
    
  }
  
  .table-container {
    transition: transform 0.3s ease; 
    
  }
  .containerIcon {
    transition: transform 0.3s ease;
  }
  .containerIcon :hover {
    transform: scale(1.3); 
   
    top: 0;
    left: 0;
   
  }
  .table-container:hover {
    transform: scale(1.3); 
  }
  video {
    border: 2px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  button {
    margin: 5px;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  button:hover {
    background-color: #0056b3;
  }
  