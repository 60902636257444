.marquee-container {
  width: 700px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative; /* Ensures that the caption is positioned relative to the container */
  background: rgba(0, 0, 0, 0.5);
}

.marquee {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
  color: #fff;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.caption {
  width: 100%;
  text-align: center;
  margin-top: 10px; /* Adjust the margin as needed to create space between the marquee and the caption */
  font-size: 14px; /* Adjust the font size as needed */
  color: #fff;
}
