/* LiveChat.css */

/* Base styles (Desktop view) */
.chat-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 100%; 
    margin: auto;
    border: 1px solid #7f083c;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fdedf4;
    overflow: auto;
}
.chat-messanger {/* LiveChat.css */

    /* Base styles (Desktop view) */
    .chat-container {
        display: flex;
        flex-direction: column;
        height: 70vh;
        width: 100%; 
        margin: auto;
        border: 1px solid #7f083c;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: #fdedf4;
        overflow: auto;
    }
    .chat-messanger {
        display: flex;
        flex-direction: column;
        height: 70vh;
        width: 100%; 
        margin: auto;
        border: 1px solid #7f083c;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: #cff4fc;
        overflow: auto;
    }
    
    .chat-header {
       border:1px solid #7f083c;
        color: #000;
        padding: 10px;
        font-size: 18px;
        text-align: center;
        border-radius: 10px 10px 0 0;
        margin-left: 1px;
        margin-right: 1px;
        font-weight: bold;
    }
    
    .chat-messages {
        flex: 1;
       
        display: flex;
        flex-direction: column;
    }
    
    .chat-message {
        display: flex;
        margin-bottom: 10px;
        align-items: flex-end;
    }
    
    .chat-message.sent {
        justify-content: flex-end;
       
    }
    
    .chat-message.received {
        justify-content: flex-start;
        
    }
    
    .chat-message-bubble {
        width: 100%;
        max-width: 60%;
        padding: 10px;
        border-radius: 20px;
        position: relative;
        word-wrap: break-word; /* Break long words */
        overflow-wrap: break-word; /* Handle long unbroken text */
        white-space: normal; /* Allow text to wrap and prevent overflow */
        
    }
    .font-size-set
    {
        font-size: 8pt;
        line-height: 1.4;
        color: #000;
    }
    .chat-message.sent .chat-message-bubble {
        background-color: #fec8df;
    }
    
    .chat-message.sent .chat-message-bubble::after {
        
        position: absolute;
        bottom: 0;
        right: 10px;
        border-width: 10px;
        border-style: solid;
        border-color: #dcf8c6 transparent transparent transparent;
    }
    
    .chat-message.received .chat-message-bubble {
        background-color: #fff;
    }
    
    .chat-message.received .chat-message-bubble::after {
        
        position: absolute;
        bottom: 0;
        left: 10px;
        border-width: 10px;
        border-style: solid;
        border-color: #f1f0f0 transparent transparent transparent;
    }
    
    .chat-message-time {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
        float: right;
       padding-right: 25px;
       color: blue;
    }
    
    .chat-input-container {
        display: flex;
        align-items: center;
        padding: 10px;
        border-top: 1px solid #7f083c;
        background-color: #f1f1f1;
    }
    
    .chat-input {
        flex: 1;
        margin-right: 10px;
    }
    
    .send-button {
        background-color: #7f083c;
        color: #ffffff;
    }
    
    .send-button:hover {
        background-color: #1ebe56;
    }
    
    .MuiTextField-root {
        flex: 1;
    }
    
    .MuiInputBase-root {
        display: flex;
        align-items: center;
    }
    
    .MuiButton-root {
        height: 100%;
        border-radius: 0;
        margin-left: 10px;
    }
    
    /* Media Queries */
    
    /* For tablets and smaller laptops (768px and above) */
    @media (max-width: 1024px) {
        .chat-message-bubble {
            max-width: 70%;
            padding: 10px;
        }
        .chat-header {
            font-size: 16px;
        }
    }
    
    /* For mobile devices (below 768px) */
    @media (max-width: 768px) {
        .chat-container {
            height: 60vh;
        }
    
        .chat-header {
            font-size: 14px;
        }
    
        .chat-message-bubble {
            max-width: 80%;
            padding: 10px;
        }
    
        .chat-message-time {
            font-size: 10px;
        }
    
        .send-button {
            font-size: 14px;
        }
    }
    
    /* For small mobile devices (below 480px) */
    @media (max-width: 480px) {
        .chat-container {
            height: 50vh;
        }
    
        .chat-message-bubble {
            max-width: 90%;
            padding: 6px;
        }
    
        .chat-header {
            font-size: 12px;
        }
    
        .chat-message-time {
            font-size: 9px;
        }
    
        .send-button {
            font-size: 12px;
        }
    }
    /* LiveChat.css */
    .emoji-picker {
        position: absolute;
        bottom: 70px; /* Adjust as needed */
        left: 20px;   /* Adjust as needed */
        z-index: 1000; /* Ensure it's above other elements */
      }
      .chat-date
      {
        font-weight: bold;
        text-align: center !important;
        border: 1px solid gray;
        padding: 5px;
        border-radius: 10px;
      }
      .chat-date-flex
      {
        text-align: center !important;
        width: 100% !important;
        max-width: 100%;
        display: flex;
        justify-content: center;
      }
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 100%; 
    margin: auto;
    border: 1px solid #7f083c;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #cff4fc;
    overflow: auto;
}

.chat-header {
   border:1px solid #7f083c;
    color: #000;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    margin-left: 1px;
    margin-right: 1px;
    font-weight: bold;
}

.chat-messages {
    flex: 1;
   
    display: flex;
    flex-direction: column;
}

.chat-message {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-end;
}

.chat-message.sent {
    justify-content: flex-end;
   
}

.chat-message.received {
    justify-content: flex-start;
    
}

.chat-message-bubble {
    width: 100%;
    max-width: 60%;
    padding: 10px;
    border-radius: 20px;
    position: relative;
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word; /* Handle long unbroken text */
    white-space: normal; /* Allow text to wrap and prevent overflow */
    
}
.font-size-set
{
    font-size: 8pt;
    line-height: 1.4;
    color: #000;
}
.chat-message.sent .chat-message-bubble {
    background-color: #fec8df;
}

.chat-message.sent .chat-message-bubble::after {
    
    position: absolute;
    bottom: 0;
    right: 10px;
    border-width: 10px;
    border-style: solid;
    border-color: #dcf8c6 transparent transparent transparent;
}

.chat-message.received .chat-message-bubble {
    background-color: #fff;
}

.chat-message.received .chat-message-bubble::after {
    
    position: absolute;
    bottom: 0;
    left: 10px;
    border-width: 10px;
    border-style: solid;
    border-color: #f1f0f0 transparent transparent transparent;
}

.chat-message-time {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
    float: right;
   padding-right: 25px;
   color: blue;
}

.chat-input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #7f083c;
    background-color: #f1f1f1;
}

.chat-input {
    flex: 1;
    margin-right: 10px;
}

.send-button {
    background-color: #7f083c;
    color: #ffffff;
}

.send-button:hover {
    background-color: #1ebe56;
}

.MuiTextField-root {
    flex: 1;
}

.MuiInputBase-root {
    display: flex;
    align-items: center;
}

.MuiButton-root {
    height: 100%;
    border-radius: 0;
    margin-left: 10px;
}

/* Media Queries */

/* For tablets and smaller laptops (768px and above) */
@media (max-width: 1024px) {
    .chat-message-bubble {
        max-width: 70%;
        padding: 10px;
    }
    .chat-header {
        font-size: 16px;
    }
}

/* For mobile devices (below 768px) */
@media (max-width: 768px) {
    .chat-container {
        height: 60vh;
    }

    .chat-header {
        font-size: 14px;
    }

    .chat-message-bubble {
        max-width: 80%;
        padding: 10px;
    }

    .chat-message-time {
        font-size: 10px;
    }

    .send-button {
        font-size: 14px;
    }
}

/* For small mobile devices (below 480px) */
@media (max-width: 480px) {
    .chat-container {
        height: 50vh;
    }

    .chat-message-bubble {
        max-width: 90%;
        padding: 6px;
    }

    .chat-header {
        font-size: 12px;
    }

    .chat-message-time {
        font-size: 9px;
    }

    .send-button {
        font-size: 12px;
    }
}
/* LiveChat.css */
.emoji-picker {
    position: absolute;
    bottom: 70px; /* Adjust as needed */
    left: 20px;   /* Adjust as needed */
    z-index: 1000; /* Ensure it's above other elements */
  }
  .chat-date
  {
    font-weight: bold;
    text-align: center !important;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 10px;
  }
  .chat-date-flex
  {
    text-align: center !important;
    width: 100% !important;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }