.hand-animation {
  transition: transform 0.3s ease;
}

.hand-animation.animate-hand {
  animation: handAnimation 1s infinite;
}

@keyframes handAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
